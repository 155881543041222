.bio{
  /* height:80vh; */
  margin: 2em;
  
}

.bio_description{
  line-height: 1.75em;
  font-size: 24px;
  text-align: center;
  margin:24px;
}

.flag{
  /* height:150px; */
  width: 450px;
  margin: 0 auto;
}

.line{
  border:1px solid red;
  margin: 0 auto;
  width:50%;
}
.district{
  color:blue;
}