.card {
  position: relative;
  overflow: hidden;
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(72, 87, 137, 0.7); 
  /* background-color: rgba(0, 0, 0, 0.5);  */
}

.queens_train {
  width: 100%;
  height: auto;
  display: block;
  transition: opacity 0.3s ease; 
}

h1{
  font-size: 52px;
}
.vote{
  color:red;
}
.vote_{
  font-weight: bolder;
}
.name{
  border-bottom: 2px solid red;
}

p.political_campaign{
  font-weight: 400;
  /* background-color: black; */
  background-color: rgb(15, 15, 133);
  line-height: 1.75em;
  padding: 2em 1em;
}

.card-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff; 
  z-index: 1; 
}

.queens_train{
  height:95vh;
  width:100%;
}


@media screen and (min-width: 300px) and (max-width: 1200px) {
  h1{
    padding-top: 1em;
  }
  p.political_campaign{
    font-size:16px;
    font-weight: 400;
    background-color: rgb(15, 15, 133);
    /* background-color: black; */
    line-height: 1.25em;
    padding: 2em 1em;
  }
}
