.image-collage {
  text-align: center;
  max-width:100%;
  margin: 0 4em;
}

.image-grid {
  width:100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px .5em;
}

.image {
  width: calc(33.33% - 10px);
  max-width: 100%;
  margin-bottom: 10px; 
  height:350px;
  width:auto;
}

h2{
  padding: 1em 0;
    font-size: 3em;
    color: blue;
}
