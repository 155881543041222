/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900 */
/* 
.noto-sans {
  font-family: "Noto Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
} */

.card-overlay {
  background: rgba(0, 0, 0, 0.5);
} 


a.nav-link{
  margin: 0 2em;
  font-family: "Noto Sans", sans-serif;
  font-size: 28px;
  display:flex;
  justify-content: center;
}


a.nav-link:hover{
  background-color:red;
  color:white;
}

h3{color:white;}