.Contact{
  /* background-color: blue; */
  background-color: rgb(15, 15, 133);
}

.email_form{
  background-color: red;
  min-height: 80vh;
}

.slogan{
  color:white;
  text-transform: uppercase;
  font-size: 32px;
  text-align: center;
}