.title{
  padding: 1em 0;
  font-size: 3em;
  color:blue;
}

.individual_issues{
  background-color: red;
}

.card-title{
  color:red;
}

.issue{
  min-height: 252px;
}

.issue:hover{
  border:2px solid blue;
}

p.description{
  font-size:15.5px;
}